import React from "react"
import { Link } from "gatsby"

import { IconContext } from "react-icons"
import { FaFacebookSquare, FaInstagram, FaTwitter } from "react-icons/fa"

import footerStyles from "../styles/components/footer.module.css"

const Footer = () => (
  <footer>
    <div className={ footerStyles.ourLocation }>
      <h5>Our Office</h5>
      <p>Team ED</p>
      <p>The IMC Block, 4th Floor</p>
      <p>141 Scout Rallos Extension</p>
      <p>Sacred Heart, Quezon City, Metro Manila PH</p>
    </div>
    <div className={ footerStyles.footerNav }>
      <h5>Navigation</h5>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about/">About</Link></li>
        <li><Link to="/services/">Our Services</Link></li>
        <li><Link to="/news/">News</Link></li>
        <li><Link to="/contact/">Contact Us</Link></li>
      </ul>
    </div>
    <div className={ footerStyles.socialMedia }>
      <h5>Follow Us</h5>
      <ul>
        <li>
          <IconContext.Provider value={{ size: "1.25em", color: "#1A4125" }}>
            <FaFacebookSquare />
          </IconContext.Provider>
          <a href="https://www.facebook.com/TEDspecialevents">Facebook</a>
        </li>
        <li>
          <IconContext.Provider value={{ size: "1.25em", color: "#1A4125" }}>
            <FaTwitter />
          </IconContext.Provider>
          <a href="https://twitter.com/TEDeventsph">Twitter</a>
        </li>
        <li>
          <IconContext.Provider value={{ size: "1.25em", color: "#1A4125" }}>
            <FaInstagram />
          </IconContext.Provider>
          <a href="https://www.instagram.com/ted_specialevent/">Instagram</a>
        </li>
      </ul>
    </div>
    <p className={ footerStyles.copyrightText }>
      Copyright © 2020, TED Events. All Rights Reserved.
    </p>
  </footer>
)

export default Footer